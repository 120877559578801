<template>
  <article class="tpl-stub mb-12 space-y-12" :data-uuid="uuid">
    <div>
      <PageHeader
        :title="title"
        :subtitle="subtitle"
        :background="image"
        bg-color="sky"
      />
    </div>

    <Wysiwyg :="body">
      <template
        v-if="wysiwygPromos?.length"
        v-for="card in wysiwygPromos"
        :key="card.uuid"
      >
        <Card :="card" />
      </template>
    </Wysiwyg>

    <section>
      <LytGridTwoUp :length="promos.length">
        <template #item="{ idx }">
          <template v-for="card in [getCardAt(promos, idx)]" :key="card?.uuid">
            <Card v-if="card" :="card" />
          </template>
        </template>
      </LytGridTwoUp>
    </section>
  </article>
</template>

<script lang="ts" setup>
import { getCardAt } from '~/utils/get-card'
import * as logger from '~/utils/logging'
import type { PageInterface } from '~/types'
const { locale } = useI18n()
const props = defineProps<Omit<PageInterface, 'author'>>()

if (!props.uuid) {
  logger.error('[stub] Missing uuid', { props })
}

const { data: _promotions } = useLazyFetch(
  `/api/data/content/${props.uuid}/promotions`,
  {
    params: { lang: locale, limit: 4 },
  },
)

const promos = computed(() => unref(_promotions)?.slice(0, 2) || [])
const wysiwygPromos = computed(() => unref(_promotions)?.slice(2) || [])
</script>
